import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function NavbarEntry({
  option,
  handleExtendNavbarOption,
  isMobileWidth,
}) {
  const [subCategoriesOpen, setSubCategoriesOpen] = React.useState(false);

  return (
    <div className="flex flex-col overflow-hidden">
      <li
        key={`${option.Title}-navbarEntry`}
        className="navbar-option mx-5 cursor-pointer"
        onMouseEnter={() => {
          if (!isMobileWidth) handleExtendNavbarOption(option);
        }}
      >
        <a href={option.Anchor}>
          {isMobileWidth && (
            <FontAwesomeIcon icon={option.Icon} style={{ marginRight: 10 }} />
          )}
          {option.Title.toUpperCase()}
        </a>{" "}
        {/* only add chevron icon if option.SubCategories is not null */}
        {option.SubCategories && (
          <FontAwesomeIcon
            icon={subCategoriesOpen ? faChevronUp : faChevronDown}
            onClick={() => {
              if (isMobileWidth) setSubCategoriesOpen(!subCategoriesOpen);
            }}
          />
        )}
      </li>
      {isMobileWidth && option.SubCategories && (
        <div
          className={`sub-categories-container ${
            subCategoriesOpen && "sub-categories-container-open"
          }`}
        >
          {option.SubCategories.map((subCategory) => {
            return (
              <ul className="mx-5 mb-4 mt-1">
                <li key={`${subCategory.Title}-subCategoryEntry`}>
                  {subCategory.Title}
                </li>
              </ul>
            );
          })}
        </div>
      )}
    </div>
  );
}
