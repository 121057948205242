import React from "react";

export default function Services() {
  return (
    <main className="z-10">
      <div className="sm:p-0 p-4 flex flex-col">
        <div className="text-center mx-auto md:px-20 flex flex-col sm:mt-8">
          <h1 className="flex flex-row justify-center large-text text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block xl:inline mr-4">Services</span>
          </h1>
          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            I need some information on the services you offer. If you don't want
            to add services we can just keep this off the site for now as it's a
            given the main service will be pet sitting.
          </p>
        </div>
      </div>
    </main>
  );
}
