import React from "react";

export default function Footer() {
  return (
    <div className="w-screen h-auto bg-slate-900 text-white text-center p-4 mt-8">
      <p>Copyright © {new Date().getFullYear()} Pet Sitting By Alice</p>

      <a href="/privacy-policy" style={{ textDecoration: "underline" }}>
        Privacy Policy
      </a>
    </div>
  );
}
