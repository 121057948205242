import React from "react";
import hero from "../../assets/images/hero.jpg";

export default function Home() {
  return (
    <main className="sm:absolute relative top-0 w-screen sm:h-screen bg-white overflow-hidden z-0">
      <div className="home-left lg:w-1/2 h-full">
        <div className="text-center lg:text-left md:px-20 px-10 flex flex-col h-full justify-center">
          <h1 className="large-text text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block xl:inline">Pet Sitting by</span>{" "}
            <span className="block text-orange-600 xl:inline">Alice</span>
          </h1>
          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            Give your pets a treat by using an independently run business that
            provides quality, loving care for your pet in your home when you
            cannot be there
          </p>
          <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div className="rounded-md shadow">
              <a
                href="/book"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 md:py-4 md:text-lg md:px-10"
              >
                Book with us
              </a>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <a
                href="/cost-calculator"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-orange-700 bg-orange-100 hover:bg-orange-200 md:py-4 md:text-lg md:px-10"
              >
                Calculate price
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 mobile-hidden"
        style={{ marginTop: 220 }}
      >
        <img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src={hero}
          alt="hero-cover"
        />
      </div>
    </main>
  );
}
