import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { sendMail } from "../common/SendEmail";

export default function Contact() {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailAddress, setEmailAddress] = React.useState("");
  const [contactNumber, setContactNumber] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  function sendForm(e) {
    e.preventDefault();
    sendMail(e.target)
      .then((res) => {
        setSuccess("Thank you! Your request has been sent successfully!");
        setLoading(false);
        clearForm();
      })
      .catch((err) => {
        setError(
          `There seems to have been an issue sending your request. Please try again. If the problem persists please email me directly on ${(
            <a href="mailto:petsitteralice@gmail.com">
              petsitteralice@gmail.com
            </a>
          )}`
        );
        setLoading(false);
      });
  }

  function clearError() {
    setError(null);
  }

  function clearForm() {
    setFirstName("");
    setLastName("");
    setEmailAddress("");
    setContactNumber("");
    setMessage("");
  }

  return (
    <main className="z-10">
      <div className="sm:p-0 p-4 flex flex-col">
        <div className="text-center mx-auto md:px-20 flex flex-col sm:mt-8">
          <h1 className="flex flex-row justify-center large-text text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block xl:inline mr-4">Contact</span>{" "}
            <span className="block text-orange-600 xl:inline">Us</span>
          </h1>
          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            If you need to get in touch with us just submit your request. If you
            can let us know what you are looking for using the message box below
            so we can be better equipt to help when we get back in touch. We
            will aim to get back to you within the day!
          </p>
        </div>
        <div className="divider" />
        <form
          className="w-full max-w-lg mx-auto mt-8"
          id="contact-form"
          onSubmit={(e) => {
            sendForm(e);
            setLoading(true);
          }}
        >
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <input type="hidden" value="Contact" name="emailType" />
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                First Name
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                name="firstName"
                type="text"
                placeholder="Jane"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  clearError();
                }}
                required
              />
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Last Name
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                name="lastName"
                type="text"
                placeholder="Doe"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  clearError();
                }}
                required
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                E-mail
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="email"
                name="emailAddress"
                type="email"
                value={emailAddress}
                placeholder="email@email.com"
                onChange={(e) => {
                  setEmailAddress(e.target.value);
                  clearError();
                }}
                required
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Contact Number
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="contact-number"
                name="contactNumber"
                type="number"
                value={contactNumber}
                placeholder="07123 456789"
                onChange={(e) => {
                  setContactNumber(e.target.value);
                  clearError();
                }}
                required
              />
              <p className="text-gray-600 text-xs italic">
                We will only use your phone number and email address for contact
                purposes
              </p>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Message
              </label>
              <textarea
                className=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                id="message"
                name="message"
                value={message}
                placeholder="Do you have any extra information to tell us? Anything you think may help us understand your request"
                onChange={(e) => {
                  setMessage(e.target.value);
                  clearError();
                }}
              ></textarea>
              <p className="text-gray-600 text-xs italic">
                We will aim to respond within 24 hrs
              </p>
              {error && (
                <div className="bg-red-500 rounded mt-2">
                  <p className="text-white italic md:text-lg text-md px-1">
                    {error}
                  </p>
                </div>
              )}
              {success && (
                <div className="bg-green-500 rounded mt-2">
                  <p className="text-white italic md:text-lg text-md px-1">
                    {success}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center">
            <div className="mr-2">
              <input
                className="shadow bg-orange-600 hover:bg-orange-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                type="submit"
                value="Send"
                disabled={loading}
              />
            </div>
            {loading && (
              <FontAwesomeIcon
                class="animate-spin"
                icon={faSpinner}
                style={{ height: 25, width: 25 }}
              />
            )}
          </div>
        </form>
      </div>
    </main>
  );
}
