import React from "react";

export default function NavbarExtended({ option, exitExtendedNav }) {
  const subCategories = option.SubCategories;

  if (subCategories)
    return (
      <div
        className="extended-nav-container bg-white"
        onMouseLeave={() => exitExtendedNav()}
      >
        <div className="extended-options-container">
          <ul className="extended-options">
            {subCategories.map((category) => {
              return (
                <li className="extended-option-container">
                  <p>{category.Title.toUpperCase()}</p>
                  <hr className="divider" />
                  <ul>
                    {category.Options.map((option) => {
                      return (
                        <li className="extended-option cursor-pointer">
                          {option}
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  return null;
}
