import {
  faCalendar,
  faImages,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import {
  // faBellConcierge,
  faCalculator,
  faHouse,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

export const navbarOptions = [
  {
    Title: "Home",
    SubCategories: null,
    Anchor: "/",
    Icon: faHouse,
  },
  {
    Title: "About",
    SubCategories: null,
    Anchor: "/about",
    Icon: faUser,
  },
  // {
  //   Title: "Services",
  //   SubCategories: [
  //     { Title: "Pet Sitting", Options: ["Cats", "Rabbits", "Other"] },
  //     { Title: "Additional Service", Options: ["Help", "Write", "These"] },
  //   ],
  //   Anchor: "/services",
  //   Icon: faBellConcierge,
  // },
  // {
  //   Title: "Gallery",
  //   SubCategories: null,
  //   Anchor: "/gallery",
  //   Icon: faImages,
  // },
  {
    Title: "Cost Calculator",
    SubCategories: null,
    Anchor: "/cost-calculator",
    Icon: faCalculator,
  },
  {
    Title: "Contact",
    SubCategories: null,
    Anchor: "/contact-us",
    Icon: faPhone,
  },
  {
    Title: "Book With Us",
    SubCategories: null,
    Anchor: "/book",
    Icon: faCalendar,
  },
];
