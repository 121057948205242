import React from "react";

export default function About() {
  return (
    <main className="z-10">
      <div className="sm:p-0 p-4 flex flex-col">
        <div className="text-center mx-auto md:px-20 flex flex-col sm:mt-8">
          <h1 className="flex flex-row justify-center large-text text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block xl:inline mr-4">About</span>{" "}
            <span className="block text-orange-600 xl:inline">Me</span>
          </h1>
          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-5xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0 text-justify">
            Pet Sitting by Alice is an independently run business providing
            quality, loving care for your pet in your home when you cannot be
            there. I’m Alice; an experienced, professional pet sitter based in
            Armadale, West Lothian. I have over 25 years’ experience in animal
            care and have cared for and had cats my entire life. I am also fully
            insured and a member of Disclosure Scotland (PVG scheme). I have
            completed a Pet First Aid Course and a What Cat&#39;s Need course so
            you can ensure your pets are in safe hands. I started Pet Sitting by
            Alice in 2018 mainly due to how much our pets prefer their own
            routine and environment being maintained during the times their
            owners are away from home. As this can already be a stressful time
            for our pets, I can ensure your pet is comforted and well cared for
            in their own home whilst you cannot be there.
          </p>
          <p className="divider"></p>

          <h1 className="mt-6 flex flex-row justify-center large-text text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block xl:inline mr-4">Services I</span>{" "}
            <span className="block text-orange-600 xl:inline">Offer</span>
          </h1>
          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            <li className="mt-4 text-justify">
              Cleaning and re-filling of food bowls/feeding stations
            </li>
            <li className="text-justify">
              Checking and cleaning of litter trays
            </li>
            <li className="text-justify">Replenishing water bowls/fountains</li>
            <li className="text-justify">
              Cleaning of cages/pet beds (particularly for small, caged animals)
            </li>
            <li className="text-justify">
              Cuddles/bonding, love and attention for your furry friend
            </li>
            <li className="text-justify">
              SMS, Whatsapp, email or messenger updates whilst you are away
            </li>
            <li className="text-justify">
              Giving of medication/taking to vets if required (vet visits may
              incur an additional fee)
            </li>
            <li className="text-justify">
              Water plants, open/close curtains, take bins out if required
            </li>
            <li className="text-justify">
              In addition to cats, these services extend to other small animals
              such as rabbits, guinea pigs, hamsters and chinchillas
            </li>
          </p>
        </div>
      </div>
    </main>
  );
}
