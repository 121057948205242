import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import NavbarEntry from "./NavbarEntry";
import NavbarExtended from "./NavbarExtended";
import { navbarOptions } from "./NavbarOptions";

export default function Navbar() {
  const [extendNavbarOption, setExtendNavbarOption] = React.useState(null);
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const isMobileWidth = window.innerWidth <= 1400;

  const handleExtendNavbarOption = (bool) => {
    setExtendNavbarOption(bool);
  };

  return (
    <>
      <div
        className={`dark-background ${
          navbarOpen ? "dark-background-active" : null
        }`}
        onClick={() => setNavbarOpen(false)}
      ></div>

      <li
        key="burger-menu-icon"
        className="menu-icon my-auto mx-3 cursor-pointer"
        onClick={() => setNavbarOpen(true)}
      >
        <FontAwesomeIcon icon={faBars} size="lg" />
      </li>

      <ul
        className={`navbar-options-container text-gray-700 ${
          navbarOpen && "navbar-options-container-open"
        }`}
      >
        <div
          className="laptop-visible m-5"
          onClick={() => setNavbarOpen(false)}
          key="close-nav-icon"
        >
          <FontAwesomeIcon icon={faClose} size="lg" />
        </div>

        {navbarOptions.map((option) => {
          // loop through navbarOptions and create list item for each
          return (
            <NavbarEntry
              option={option}
              isMobileWidth={isMobileWidth}
              handleExtendNavbarOption={handleExtendNavbarOption}
              key={`${option.Title}-entry`}
            />
          );
        })}
      </ul>

      {/* if extendNavbarOption is not null - show the extended navbar passing in the 
              navbar option
          */}
      {extendNavbarOption && !isMobileWidth && (
        <NavbarExtended
          option={extendNavbarOption}
          exitExtendedNav={() => handleExtendNavbarOption(null)}
        />
      )}
    </>
  );
}
