import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import logo from "../../assets/images/logo.png";
import Navbar from "../navbar/Navbar";

export default function Header() {
  return (
    <>
      <header className="max-h-60 w-screen flex flex-row px-3 header z-10 bg-white">
        <Navbar />
        <ul className="flex flex-row h-60 mobile-width-full">
          <div className="pull-right flex flex-row h-60">
            <li key="basket-icon" className="my-auto mx-3 cursor-pointer">
              <a href="/contact-us">
                <FontAwesomeIcon icon={faPhone} size="lg" />
              </a>
            </li>
          </div>
        </ul>

        <div className="h-60 p-4 header-logo-container">
          <img
            className="h-full w-auto header-logo"
            alt="Swipe Fashion"
            src={logo}
          />
        </div>
      </header>
    </>
  );
}
