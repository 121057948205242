import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { sendMail } from "../common/SendEmail";

export default function Bookings() {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailAddress, setEmailAddress] = React.useState("");
  const [contactNumber, setContactNumber] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [postcode, setPostcode] = React.useState("");
  const [totalDays, setTotalDays] = React.useState("");
  const [petNumber, setPetNumber] = React.useState("");
  function sendForm(e) {
    e.preventDefault();
    sendMail(e.target)
      .then((res) => {
        setSuccess("Thank you! Your request has been sent successfully!");
        setLoading(false);
        clearForm();
      })
      .catch((err) => {
        setError(
          `There seems to have been an issue sending your request. Please try again. If the problem persists please email me directly at petsitteralice@gmail.com`
        );
        setLoading(false);
      });
  }

  function clearError() {
    setError(null);
  }

  function clearForm() {
    setFirstName("");
    setLastName("");
    setEmailAddress("");
    setContactNumber("");
    setMessage("");
    setPostcode("");
    setTotalDays("");
    setPetNumber("");
  }

  return (
    <main className="z-10">
      <div className="sm:p-0 p-4 flex flex-col">
        <div className="text-center mx-auto md:px-20 flex flex-col sm:mt-8">
          <h1 className="large-text text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block xl:inline">Book With</span>{" "}
            <span className="block text-orange-600 xl:inline">Us</span>
          </h1>
          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            If you are looking to request a booking through us just send us a
            message, we will be happy to help! The information we collect below
            will only be used to deal with your request.
          </p>
        </div>
        <div className="divider" />
        <form
          className="w-full max-w-lg mx-auto mt-8"
          id="contact-form"
          onSubmit={(e) => {
            sendForm(e);
            setLoading(true);
          }}
        >
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <input type="hidden" value="Booking" name="emailType" />
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                First Name
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                name="firstName"
                type="text"
                placeholder="Jane"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  clearError();
                }}
                required
              />
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Last Name
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                name="lastName"
                type="text"
                placeholder="Doe"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  clearError();
                }}
                required
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                E-mail
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="email"
                name="emailAddress"
                type="email"
                placeholder="email@email.com"
                value={emailAddress}
                onChange={(e) => {
                  setEmailAddress(e.target.value);
                  clearError();
                }}
                required
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Contact Number
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="contact-number"
                name="contactNumber"
                type="number"
                placeholder="07123 456789"
                value={contactNumber}
                onChange={(e) => {
                  setContactNumber(e.target.value);
                  clearError();
                }}
                required
              />
              <p className="text-gray-600 text-xs italic">
                We will only use your phone number and email address for contact
                purposes
              </p>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-petnumber"
              >
                Number of pets to be looked after
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-petNumber"
                name="petNumber"
                type="text"
                placeholder="2 Cats etc"
                value={petNumber}
                onChange={(e) => {
                  setPetNumber(e.target.value);
                  clearError();
                }}
                required
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="totalDays"
              >
                No. of days pet-sitting required
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="totalDays"
                name="totalDays"
                type="text"
                placeholder="10 Days etc"
                value={totalDays}
                onChange={(e) => {
                  setTotalDays(e.target.value);
                  clearError();
                }}
                required
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="postcode"
              >
                Postcode
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="postcode"
                name="postcode"
                type="text"
                placeholder="EH00 0AA"
                value={postcode}
                onChange={(e) => {
                  setPostcode(e.target.value);
                  clearError();
                }}
                required
              />
              <p className="text-gray-600 text-xs italic">
                We need this information to confirm we are able to help
              </p>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Additional Information
              </label>
              <textarea
                className=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                id="message"
                name="message"
                value={message}
                placeholder="Do you have any extra information to tell us? Extra days, pet medical requirements etc"
                onChange={(e) => {
                  setMessage(e.target.value);
                  clearError();
                }}
              ></textarea>
              <p className="text-gray-600 text-xs italic">
                We will aim to respond within 24 hrs
              </p>
              {error && (
                <div className="bg-red-500 rounded mt-2">
                  <p className="text-white italic md:text-lg text-md px-1">
                    {error}
                  </p>
                </div>
              )}
              {success && (
                <div className="bg-green-500 rounded mt-2">
                  <p className="text-white italic md:text-lg text-md px-1">
                    {success}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center">
            <div className="mr-2">
              <input
                className="shadow bg-orange-600 hover:bg-orange-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                type="submit"
                value="Send"
                disabled={loading}
              />
            </div>
            {loading && (
              <FontAwesomeIcon
                class="animate-spin"
                icon={faSpinner}
                style={{ height: 25, width: 25 }}
              />
            )}
          </div>
        </form>

        <p className="divider"></p>
        <div className="text-center mx-auto md:px-20 flex flex-col sm:mt-8">
          <h1 className="mt-6 flex flex-row justify-center large-text text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block xl:inline mr-4">
              What You Need{" "}
              <span className="block text-orange-600 xl:inline">To Know</span>
            </span>
          </h1>
          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-5xl  sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            <li className="mt-4 text-justify">
              I am experienced in animal care and have cared for and had cats my
              entire life
            </li>
            <li className="text-justify">
              I am fully insured, have completed a Pet First Aid Course and a
              What Cat&#39;s Need course so you can ensure your pet is in safe
              hands
            </li>
            <li className="text-justify">
              The safety of your pet(s) and your home is my priority. Your
              key(s) will be kept on my person at all times and your personal
              and address information remains confidential.
            </li>
            <li className="text-justify">
              I am based in Armadale, West Lothian and I am willing to travel to
              most areas of West Lothian for visits.
            </li>
            <li className="text-justify">
              All visits last around 30 minutes to ensure your pet has enough
              human interaction and has been well cared for, however visits can
              be tailored to suit your pets needs
            </li>
            <li className="text-justify">
              Rates are based on upto 2 pets living in the household; there is a
              £2.00 charge for each additional pet, per day.
            </li>
            <li className="text-justify">
              I am unable to offer any dog walking services at this time
            </li>
            <li className="text-justify">
              I am unable to offer your pet(s) to stay at my home
            </li>
          </p>
        </div>

        <p className="divider"></p>
        <div className="text-center mx-auto md:px-20 flex flex-col sm:mt-8">
          <h1 className="mt-6 flex flex-row justify-center large-text text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block xl:inline mr-4">
              Additional{" "}
              <span className="block text-orange-600 xl:inline">
                Information
              </span>
            </span>
          </h1>
          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:mx-auto sm:max-w-5xl md:mt-5 md:text-xl xl:mx-0">
            <li className="mt-4 text-justify">
              Please advise prior to the services starting of any health or
              medical concerns your pet has.
            </li>
            <li className="text-justify">
              Please advise what you would like me to do with your house/flat
              key once the pet sitting services have finished. I can post the
              through the letter box after the visit, or I can retain the keys
              until you have returned home and drop them off to your home in
              person.
            </li>
            <li className="text-justify">
              Please advise if you would like updated on how your pet is doing,
              how often you would like updated and your preferred contact
              method.
            </li>
            <li className="text-justify">
              On the visit prior to the services starting, please allow me to
              meet your pet(s) and show me where their food, bowls and litter
              tray are.
            </li>
            <li className="text-justify">
              Please let me know the frequency/volume of food they eat.
            </li>
          </p>
        </div>
      </div>
    </main>
  );
}
