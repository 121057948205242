import { init } from "@emailjs/browser";
import emailjs from "@emailjs/browser";

// init email js
init("user_blx1DgytvZtzT4UkR609U");

export function sendMail(form) {
  return emailjs.sendForm(
    "service_uk57ied",
    "template_2e0m05v",
    form,
    "user_blx1DgytvZtzT4UkR609U"
  );
}
