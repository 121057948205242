import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Calculator from "./components/calculator/Calculator";
import Header from "./components/header/Header";
import Home from "./components/home/Home";
import Services from "./components/services/Services";
import Gallery from "./components/gallery/Gallery";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Bookings from "./components/bookings/Bookings";
import LogRocket from "logrocket";
import Footer from "./components/footer/Footer";

LogRocket.init("petsittingbyalice/petsittingbyalice");

function App() {
  return (
    <Router>
      <div
        style={{ minHeight: "100vh", minWidth: "100vw", position: "relative" }}
      >
        <Header />
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/gallery" element={<Gallery />} />
          {/* <Route path="/cost-calculator" element={<Calculator />} /> */}
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/book" element={<Bookings />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
